import { makeStyles } from "@material-ui/core/styles";
import { theme as themeStyles } from "../../styles/theme-constants";

export const signupStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '5px',
    padding: '10px 20px',
    gap:'10px'
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '50vh',
  },
  welcomeButton: {
    background: 'linear-gradient(122deg, #83EBDE 39%, #AFBCED 100%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .2)',
    color: 'white',
    height: 50,
    padding: '0 30px',
    marginTop: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
    width: 'auto'
  },
  cardContainer: {
    width: '100%',
    height: '100%',
  },
  bigContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  containedPrimary: {
    backgroundColor: themeStyles.primary
  },
  littleContainer: {
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  bar: {
    width: '100%',
  },
  barColorPrimary: {
    background: `${themeStyles.primary}`
  },
  thankyouMessage: {
    padding: '0rem 0px 2rem 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtitle: {
    marginBottom: '2rem'
  },
  thankyouLabel:{
    fontSize:'1.2rem'
  },
  icon: {
    width: 60,
    height: 60,
    marginBottom: '1rem',
  },
  barRoot: {
    height: '5px'
  },
  paddedLogoContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2rem',
  },
  bigImageContainer: {
    marginTop: '1rem',
    width: '40%',
  },
  bigImageFirstContainer: {
    width: '60%',
  },
  titleFirst: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: themeStyles.grayText,
    textAlign: 'center',
    marginBottom: '10px',
  },
  subTitleFirst: {
    fontSize: '0.9rem',
    color: themeStyles.grayText,
    textAlign: 'center',
    textTransform: 'none',
    marginBottom: '3rem',
    lineHeight: '1.2rem',
    padding: '0rem 2rem'
  },
  imageContainer: {
    width: '40%'
  },
  checkBoxColor: {
    color: `${themeStyles.primary} !important`
  },
  lowercaseInput: {
    textTransform: 'lowercase'
  },
  capitalizeInput: {
    textTransform: 'capitalize'
  },
  noAdornment: {
    display: 'none'
  },
  awesomeLabel: {
    fontSize: '1.3rem',
    backgroundColor: '#fff',
    marginLeft: '-5px',
    padding: '0 6px',
  },
  containerPhone: {
    display: 'flex',
    gap:'10px'
  },
  textCode: {
    width: '50%',
    marginRight: '10px',
    "@media (max-width: 425px)": {
      width: '100% !important',
    }
  },
  textPhone: {
    "@media (max-width: 425px)": {
      width: '100% !important',
    }
  },
  showPassword: {
    color: themeStyles.primary,
    fontSize: 'small'
},
  spanFormStep: {
    fontSize: 'small',
    padding: '0.5rem 0 0 0.2rem',
    marginLeft: '10px',
    color: themeStyles.gray
  }
}));
